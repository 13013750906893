<template>
  <component :is="template"></component>
</template>
<script>
import MultimediaList from "./multimediaList";
import MultimediaNew from "./multimediaNew";
import MultimediaEdit from "./multimediaEdit";

import { mapGetters } from "vuex";
export default {
  components: {
    MultimediaList,
    MultimediaNew,
    MultimediaEdit
  },
  data() {
    return {
      multimedia: {
        id: 0,
        fechaRegistro: new Date(),
        fechaPublicacion: new Date(),
        fechaActividad: new Date(),
        titulo: "",
        descripcion: "",
        anulado: true,
        userRegistro: 0,
        detalle: {
          id: 0,
          titulo: "",
          ruta: "",
          extension: "",
          tipoArchivo: "",
          userRegistro: 0,
          multimedia: "",
          multimediaTipo: {
            id: 0,
            descripcion: "",
            anulado: true,
            orden: "",
          },
        },
      },
    };
  },
  mounted() {
    let muta = {};
    //console.info(this.$route.query.oper)
    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.multimedia = this.multimedia;
    } else {
      muta.oper = this.$route.query.oper;
    }
    this.$store.commit("gsdialogo/OPERACION_MULT", muta);
  },

  computed: { ...mapGetters({ template: "gsdialogo/getMultTpl" }) },
};
</script>
<style>
</style>