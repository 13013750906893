<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p>Se gestiona toda multimedia como álbums de fotos.</p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="open('new')"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          dataKey="id"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} álbums"
          responsiveLayout="scroll"
          :globalFilterFields="['titulo', 'descripcion']"
        >
          <template #header>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
            >
              <h5 class="p-m-0">Administrar Multimedia</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="id" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="fecha" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{ slotProps.data.fechaPublicacion }}
            </template>
          </Column>

          <Column field="titulo" header="Titulo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Título</span>
              {{ formatCurrency(slotProps.data.titulo) }}
            </template>
          </Column>
          <Column field="resumen" header="Resumen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Resumen</span>
              {{ formatCurrency(slotProps.data.descripcion) }}
            </template>
          </Column>
          <Column field="rating" header="Rating" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Rating</span>
              <Rating
                :modelValue="slotProps.data.rating"
                :readonly="true"
                :cancel="false"
              />
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Está seguro de querer borrar la multimedia:
              <b>{{ product.titulo }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      products: null,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},

      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        titulo: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
  },
  mounted() {
    this.product = this.model;
    this.listarData();
  },
  methods: {
    listarData(e) {
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/MULTIMEDIA_LIST", e === undefined ? true : e)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },

    open(operacion) {
      if (operacion == "new") {
        this.product = {
          id: 0,
          fechaPublicacion: new Date(),
          titulo: "",
          descripcion: "",
          anulado: true,
          userRegistro: 0,
          detalle: [],
        };
      }
      let mutacion = {
        oper: operacion,
        multimedia: this.product,
      };

      this.$router.push({
        name: "dialogo/multimedia",
        query: { oper: operacion },
      });
      this.$store.commit("gsdialogo/OPERACION_MULT", mutacion);
    },
    formatCurrency(value) {
      if (value.length >= 80) {
        return value.substring(0, 80) + "...";
      } else {
        return value;
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.open("edit");
    },

    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },

    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      let id = this.product.id;
      this.deleteProductDialog = false;
      this.product = {};

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/MULTIMEDIA_DELETE", id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Multimedia borrado id: " + res.id,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
  },
  computed: { ...mapGetters({ model: "gsdialogo/getModelMult" }) },
};
</script>
<style scoped lang="scss">
.calendar-body {
  height: 50px;
}
</style>
