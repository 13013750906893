<template>
  <div>
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card layout-toppanel">
          <p>Editar álbums de multimedia</p>
        </div>
        <div class="card">
          <Toast />

          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                label="Guardar"
                icon="pi pi-save"
                class="p-button-info p-mr-2"
                @click="guardar"
              />
            </template>
            <template v-slot:right>
              <Button
                label="Salir"
                icon="pi pi-arrow-left"
                class="p-button-danger"
                @click="open('list')"
              />
            </template>
          </Toolbar>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-lg-12">
              <h5>Título</h5>
              <InputText
                id="name"
                v-model.trim="product.titulo"
                required="true"
                placeholder="Titulo o nombre del album multimedia"
                autofocus
                :class="{ 'p-invalid': submitted && !product.titulo }"
              />
              <small class="p-invalid" v-if="submitted && !product.titulo"
                >Título es requerido.</small
              >
            </div>
            <div class="p-col-12 p-lg-12">
              <h5>Descripción</h5>
              <Textarea
                v-model="product.descripcion"
                required="true"
                rows="5"
                cols="20"
              />
            </div>

            <div class="p-col-12 p-lg-4">
              <h5>Fecha Publicación</h5>
              <Calendar
                :showIcon="true"
                :showButtonBar="true"
                v-model="product.fechaPublicacion"
                dateFormat="dd/mm/yy"
              ></Calendar>
            </div>
            <div class="p-col-12 p-lg-12">
              <DataTable
                ref="dt"
                v-model:selection="select"
                :value="product.detalle"
                :paginator="true"
                :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} multimedias"
                responsiveLayout="scroll"
              >
                <template #header>
                  <div
                    class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
                  >
                    <span class="p-m-0">
                      <Button
                        label="Agregar Imagen"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="openNew()"
                      />
                    </span>
                  </div>
                </template>
                <Column
                  selectionMode="single"
                  headerStyle="width: 3rem"
                ></Column>
                <Column header="Imagen">
                  <template #body="slotProps">
                    <span class="p-column-title">Multimedia</span>
                    <img
                      :style="{ width: '150px' }"
                      :src="
                        slotProps.data.id != 0
                          ? 'https://dev2.regionsanmartin.gob.pe/gsdialogo/api/v1/recursos/' +
                            slotProps.data.id
                          : 'https://dev2.regionsanmartin.gob.pe/gsdialogo/api/v1/recursos/temp?ruta=' +
                            getFileName(slotProps.data.ruta)
                      "
                      :alt="slotProps.data.titulo"
                      class="product-image"
                    />
                  </template>
                </Column>
                <Column field="name" header="Descripción" :sortable="true">
                  <template #body="slotProps">
                    <span class="p-column-title">Descripción</span>
                    {{ slotProps.data.titulo }}
                  </template>
                </Column>
                <Column headerStyle="width: 120px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-warning"
                      @click="openDeleteImagen(slotProps.data)"
                    />
                  </template>
                </Column>
              </DataTable>
              <small
                class="p-invalid"
                v-if="submitted && product.detalle.length == 0"
                >multimedia son requerido.</small
              >
              <Dialog
                v-model:visible="productDialog"
                :style="{ width: '550px' }"
                header="Detalles de la multimedia"
                :modal="true"
                class="p-fluid"
              >
                <div class="p-field">
                  <label for="titulo">Nombre o detalle</label>
                  <InputText
                    id="titulo"
                    v-model.trim="detalle.titulo"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted1 && !detalle.titulo }"
                  />
                  <small class="p-invalid" v-if="submitted1 && !detalle.titulo"
                    >Nombre o detalle es requerido.</small
                  >
                </div>

                <div class="p-field">
                  <FileUpload
                    mode="basic"
                    :customUpload="true"
                    @uploader="myUploader"
                    :fileLimit="1"
                    :auto="true"
                  />
                  <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
                  <small class="p-invalid" v-if="submitted && !filetitulo">
                    Archivo es requerido.</small
                  >
                </div>

                <template #footer>
                  <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="hideDialog"
                  />
                  <Button
                    label="Agregar"
                    icon="pi pi-check"
                    class="p-button-text"
                    @click="saveProduct"
                  />
                </template>
              </Dialog>

              <Dialog
                v-model:visible="deleteDialogoImagen"
                :style="{ width: '450px' }"
                header="Confirm"
                :modal="true"
              >
                <div class="confirmation-content">
                  <i
                    class="pi pi-exclamation-triangle p-mr-3"
                    style="font-size: 2rem"
                  />
                  <span v-if="detalle"
                    >Está seguro de borrar este item: <b>{{ detalle.titulo }}</b
                    >?</span
                  >
                </div>
                <template #footer>
                  <Button
                    label="No"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="deleteDialogoImagen = false"
                  />
                  <Button
                    label="Si"
                    icon="pi pi-check"
                    class="p-button-text"
                    @click="deleteImagen"
                  />
                </template>
              </Dialog>
            </div>
          </div>
          <div class="p-col-12 p-lg-12">
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="guardar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    this.action = "gsdialogo/MULTIMEDIA_UPDATE";
    return {
      urlImage: "",
      submitted: false,
      submitted1: false,
      deleteDialogoImagen: false,
      select: null,
      product: {},
      filetitulo: "",
      filetype: "",
      detalle: {},
      products: [],
      productDialog: false,
    };
  },
  methods: {
    openDeleteImagen(product) {
      this.detalle = product;
      this.deleteDialogoImagen = true;
    },
    deleteImagen() {
      this.product.detalle = this.product.detalle.filter(
        (val) => val.ruta !== this.detalle.ruta
      );

      this.deleteDialogoImagen = false;
      this.detalle = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Imagen borrada",
        life: 3000,
      });
    },
    hideDialog() {
      this.productDialog = false;
    },
    myUploader(file) {
      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.detalle.tipoArchivo = file.files[0].type;
              this.detalle.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    saveProduct() {
      this.submitted1 = true;
      if (this.detalle.titulo.trim() && this.filetitulo.trim()) {
        this.product.detalle.push(this.detalle);
        this.productDialog = false;
      }
    },
    guardar() {
      this.submitted = true;
      let modelo = this.product;
      let fecha = new Date(modelo.fechaPublicacion);

      if (this.product.titulo.trim()) {
        if (this.product.detalle.length > 0) {
          modelo.fechaPublicacion =
            fecha.getDate() +
            "/" +
            (fecha.getMonth() + 1) +
            "/" +
            fecha.getFullYear();
          this.$swal({
            width: 370,
            text: "Cargando...",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: () => {
              this.$swal.showLoading();
              this.$store
                .dispatch(this.action, modelo)
                .then((res) => {
                  this.$swal.close();
                  this.open("list");
                  this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Multimedia guardada con id: " + res.id,
                    life: 3000,
                  });
                })
                .catch((error) => {
                  this.$swal.close();
                  if (
                    error.response.status == 401 ||
                    error.response.status == 500
                  ) {
                    this.$swal(
                      error.response.data.errorMessage,
                      "Ud. no tiene permitido esta función",
                      "warning"
                    );
                  }
                });
            },
          }).then((result) => {
            console.log(result);
          });
        }
      }
    },
    getFileName(e) {
      let name = e.split(/[\\/]/g).pop().split(".")[0];
      let extension = "";
      if (e.includes(".")) {
        extension = "." + e.split(/[\\/]/g).pop().split(".")[1];
      }
      return name + extension;
    },
    openNew() {
      this.filetitulo = "";
      this.filetype = "";
      this.detalle = {
        id: 0,
        titulo: "",
        ruta: "",
        extension: "",
        tipoArchivo: "",
        userRegistro: 0,
        multimediaTipo: {
          id: 1,
          descripcion: "",
          anulado: true,
          orden: "",
        },
      };
      this.submitted1 = false;
      this.productDialog = true;
    },
    open(operacion) {
      let mutacion = {
        oper: operacion,
        multimedia: {
          detalle: [],
        },
      };

      this.$router.push({
        name: "dialogo/multimedia",
        query: { oper: operacion },
      });
      this.$store.commit("gsdialogo/OPERACION_MULT", mutacion);
    },
  },
  mounted() {
    this.product = this.model;
    this.product.fechaPublicacion = new Date(
      this.product.fechaPublicacion.substring(6, 10) +
        "-" +
        this.product.fechaPublicacion.substring(3, 5) +
        "-" +
        this.product.fechaPublicacion.substring(0, 2)
    );
  },
  computed: {
    ...mapGetters({ model: "gsdialogo/getModelMult", url: "gsdialogo/getUrl" }),
  },
};
</script>
<style>
</style>